import { useQuery } from 'react-query';
import { HeartRateHistory } from '../components/HeartRateChart/HeartRateChart';

interface HealthState {
  heartRate: number;
  heartRateHistory: HeartRateHistory;
  lastSync: Date;
  source: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
}

type HeartRateHistoryFetched = {
  date: string;
  value: number;
}[];

const fetchHealthData = async (): Promise<Partial<HealthState>> => {
  const res = await fetch(
    `https://storage.googleapis.com/life-sync/data.json?timestamp=${Date.now()}`
  );
  const data = await res.json();

  const resultHeartRate = data.heartRate;
  const resultHeartRateHistory = (
    data.heartRate1HourHistory as HeartRateHistoryFetched
  ).map((item) => ({
    date: new Date(item.date),
    value: item.value,
  }));
  const resultLastSync = new Date(data.timestamp).getTime();

  // if last sync older than 1 day, disregard the health data
  if (resultLastSync < Date.now() - 1000 * 60 * 60 * 24) {
    return {
      city: data.city,
      country: data.country,
      latitude: data.latitude,
      longitude: data.longitude,
    };
  }

  return {
    heartRate: resultHeartRate,
    heartRateHistory: resultHeartRateHistory.map(
      (item) =>
        ({
          date: new Date(item.date),
          value: item.value,
        } as HeartRateHistory[number])
    ),
    lastSync: new Date(resultLastSync),
    source: data.source,
    city: data.city,
    country: data.country,
    latitude: data.latitude,
    longitude: data.longitude,
  };
};

export const useHealth = (): Partial<HealthState> & {
  isLoading: boolean;
  isError: boolean;
} => {
  const { data, isLoading, isError } = useQuery('healthData', fetchHealthData, {
    // Refetch every 5 minutes
    refetchInterval: 1000 * 60 * 5,
  });

  return {
    heartRate: data?.heartRate,
    heartRateHistory: data?.heartRateHistory,
    lastSync: data?.lastSync,
    source: data?.source,
    city: data?.city,
    country: data?.country,
    latitude: data?.latitude,
    longitude: data?.longitude,
    isLoading,
    isError,
  };
};
